<template>
  <div style="padding: 5px;min-height: 100vh;background-size: 100% 100%"
       :style="{backgroundImage:'url(' + data.backgroundImage + ')'}">
    <div v-if="success" class="success">
      <img src="https://xinyuan-data.oss-cn-hangzhou.aliyuncs.com/images/successIcon.png" alt="提交成功">
      <div class="success-text">
        <b>提交成功！</b>
        <p>您的信息提交成功，感谢您的参与</p>
      </div>
      <div class="success-more">
        <b @click="go_back()">点击返回首页</b>
      </div>
    </div>
    <div v-if="!success" class="formIndex">
      <div style="background-color: #fbfbfb;padding: 15px 20px;margin: auto;">
        <!-- <div style="font-size: 14px;font-weight: bold;text-align: center">{{ this.data.name }}</div> -->
        <div class="html" v-html="data.introduce" v-if="data.introduce"></div>
        <f-render
            v-if="id"
            v-model="formData"
            :request-fn="handleSubmit"
            @request-success="handleSuccess"
            :config="data.jsonData"
            :comps="comps"
            pure
        ></f-render>
      </div>
    </div>
  </div>
</template>

<script>
import vueFormGallery from '@/components/f-render/fixtures/extends/gallery';
import imagesUploader from '@/components/f-render/fixtures/extends/image-uploader';
import comps from '@/components/f-render/fixtures/comps';
import vueTreeSelect from '@/components/f-render/fixtures/extends/tree-select';
import vueUploadFile from '@/components/f-render/fixtures/extends/upload-file';
import {saveForm, getForm} from '@/api/index'

export default {
  name: "index",
  data() {
    return {
      comps: comps.concat(vueFormGallery, imagesUploader, vueTreeSelect, vueUploadFile),
      formData: {},
      id: null,
      data: {},
      loading: null,
      width: null,
      formConfig: {},
      success: false
    }
  },
  computed: {
    winWidth: function () {
      return document.documentElement.clientWidth || document.body.clientWidth;
    },
    winHeight: function () {
      return document.documentElement.clientHeight || document.body.clientHeight;
    }
  },
  mounted() {
    this.loading = this.$loading({
      lock: true,
      text: '请稍后,加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    this.id = this.$route.params.id
    if (this.id) {
      this.init()
    } else {
      this.error()
    }
    this.$nextTick(() => {
      window.addEventListener('resize', () => { //监听浏览器窗口大小改变
        this.width = this.$refs.form.$root.$el.clientWidth / 2 + 'px'
      });
    })
  },
  methods: {
    async init() {
      const data = {
        id: this.id,
      }
      const res = await getForm(data)
      this.data = res.data
      document.title = this.data.name
      this.loading.close()
    },
    go_back() {
		this.formData = {}
      this.success = false
    },
    error() {
      this.$alert('活动已经结束,谢谢您的使用', '活动结束', {
        center: true,
        showConfirmButton: false,
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${action}`
          });
        }
      });
    },
    async handleSubmit(e) {
      const data = {
        formId: this.id,
        content: e
      }
      try {
        await saveForm(data)
        this.success = true
      } catch (e) {
        this.success = false
        this.$message.error("提交失败~");
      }
      return Promise.resolve();
    },
    handleSuccess() {
    }
  }
}
</script>

<style>
@media screen and (max-width: 750px) {
  .el-message-box {
    width: 60% !important;
  }
}

.formIndex {
  max-width: 700px;
  margin: auto;
}

.success img {
  width: 60%;
  margin-top: 10vh;
}

.success {
  position: relative;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.success b {
  color: #2ba0f8;
  font-size: 18px;
  font-weight: normal;
}

.success-text p {
  color: #acacac;
  letter-spacing: 1px;
  font-size: 16px;
}

.success-more {
  width: 100%;
  position: absolute;
  bottom: 10vh;
  left: 0;
  text-align: center;
  letter-spacing: 1px;
}
</style>
