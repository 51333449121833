import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui'
import EleForm from 'vue-ele-form'
import 'element-ui/lib/theme-chalk/index.css'

// 引入本地 f-render
import FRender from './components/f-render'
import vueFormGallery from 'vue-ele-form-gallery';
import vueImageUploader from 'vue-ele-form-image-uploader';
import EleFormTreeSelect from 'vue-ele-form-tree-select'
import EleFormUploadFile from 'vue-ele-form-upload-file'


Vue.config.productionTip = false
import 'lib-flexible'
Vue.use(ElementUI)
Vue.use(EleForm)
// 注册全局组件
Vue.component('f-render', FRender)
Vue.component('gallery', vueFormGallery);
Vue.component('image-uploader', vueImageUploader);
Vue.component('tree-select', EleFormTreeSelect);
Vue.component('upload-file', EleFormUploadFile)


new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
