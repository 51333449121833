var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('perfect-scrollbar',{staticClass:"f-render-scrollarea main-content"},[_c('ele-form',_vm._b({ref:"ele-form",attrs:{"isShowBackBtn":false,"formDesc":_vm.frender.formDesc,"order":Object.keys(_vm.frender.formDesc)},scopedSlots:_vm._u([{key:"form-content",fn:function(ref){
var props = ref.props;
var formDesc = ref.formDesc;
var formErrorObj = ref.formErrorObj;
return [(_vm.isRenderFinish)?_c('vue-draggable',{staticStyle:{"padding-bottom":"80px"},attrs:{"animation":200,"disabled":false,"list":_vm.frender.formItemList,"group":"form","tag":"el-row"},on:{"add":_vm.handleAdd,"end":_vm.handleMoveEnd,"start":_vm.handleMoveStart}},[(_vm.frender.formItemList.length === 0)?_c('div',{staticClass:"form-area-placeholder"},[_vm._v(" 从左侧拖拽来添加表单项 ")]):[_vm._l((formDesc),function(formItem,field,index){return [(formItem._vif)?_c('el-col',_vm._b({key:field,staticClass:"form-item",class:{
                'ele-form-col--break': formItem.break,
                'form-item-active': _vm.frender.currentIndex === index
              },nativeOn:{"click":function($event){return _vm.handleFormItemClick(index)}}},'el-col',formItem._colAttrs,false),[_c('el-form-item',{attrs:{"error":formErrorObj ? formErrorObj[field] : null,"prop":field,"label":props.isShowLabel && formItem.isShowLabel !== false
                    ? formItem.label
                    : null,"label-width":formItem.labelWidth || undefined}},[_c(formItem._type,{ref:field,refInFor:true,tag:"component",attrs:{"disabled":props.disabled || formItem._disabled,"desc":formItem,"options":formItem._options,"field":field},model:{value:(formItem.default),callback:function ($$v) {_vm.$set(formItem, "default", $$v)},expression:"formItem.default"}}),(formItem.tip)?_c('div',{staticClass:"ele-form-tip",domProps:{"innerHTML":_vm._s(formItem.tip)}}):_vm._e()],1),(_vm.frender.currentIndex === index)?_c('i',{staticClass:"el-icon-delete form-item-delete-btn",on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(index)}}}):_vm._e()],1):_vm._e()]})]],2):_vm._e()]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},'ele-form',_vm.frender.formBindProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }