import request from '@/plugins/request'

/**
 * 获取表单
 * @param 无
 */
export function getForm(data) {
    return request({
        url: '/user-form/'+ data.id,
        method: 'GET'
    })
}

/**
 * 提交表单
 * @param 无
 */
export function saveForm(data) {
    return request({
        url: '/form-datas',
        method: 'POST',
        data
    })
}
