import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/page/Index";

Vue.use(VueRouter);

const routes = [
    {
        path: "/:id",
        name: "Home",
        component: Index
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
