/**
 *  业务配置
 * */

const Setting = {
    /**
     * 基础配置
     * */
    // 网页标题的后缀
    titleSuffix: '表单收集系统',
    // 路由模式，可选值为 history 或 hash
    // 页面切换时，是否显示模拟的进度条
    showProgressBar: true,
    // 接口请求地址
    apiBaseURL: 'https://xinyuan-form-api.jstcnet.com',
    // OSS附件地址
    // ossURL: 'https://cnjsb-img.jstcnet.com',
    // accountId
    // accountId: '5fb63baf42ec1c0eb24065b7',
    // X-Identification
    // XIdentification: 'member',
    // 接口请求返回错误时，弹窗的持续时间，单位：秒
    modalDuration: 3,
    // 接口请求返回错误时，弹窗的类型，可选值为 Message 或 Notice
};

export default Setting;
